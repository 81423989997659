<template>
  <div class="map_wrap">
    <div id="myChartMap" class="chart_class"></div>
    <Numbers class="number_position" />
  </div>
</template>
<script>
import * as echarts from "echarts";
import Numbers from "@/components/Numbers";
import China from "/public/province/china.json";
let geoCoordMap = {
  北京: [116.397128, 39.916527],
  天津: [117.246, 39.117],
  河北: [114.498, 38.042],
  山西: [112.551, 37.893],
  内蒙古: [111.686, 40.819],
  辽宁: [123.418, 41.799],
  吉林: [125.324, 43.871],
  黑龙江: [126.645, 45.758],
  上海: [121.445, 31.213],
  江苏: [118.769, 32.048],
  浙江: [120.165, 30.319],
  安徽: [117.275, 31.861],
  福建: [119.303, 26.071],
  江西: [115.889, 28.671],
  山东: [117.009, 36.663],
  河南: [108.43, 37.51],
  湖北: [114.279, 30.573],
  湖南: [112.967, 28.197],
  重庆: [106.549, 29.581],
  四川: [104.071, 30.67],
  贵州: [106.7, 26.572],
  云南: [102.702, 25.051],
  西藏: [91.126, 29.656],
  陕西: [108.969, 34.285],
  甘肃: [103.751, 36.068],
  青海: [101.778, 36.621],
  宁夏: [106.263, 38.468],
  新疆: [87.585, 43.781],
  广东: [113.265, 23.108],
  广西: [108.295, 22.838],
};
let mapArray = [
  { name: "北京", value: 352 },
  { name: "天津", value: 789 },
  { name: "河北", value: 1462 },
  { name: "山西", value: 1456 },
  { name: "内蒙古", value: 1456 },
  { name: "辽宁", value: 2345 },
  { name: "吉林", value: 1235 },
  { name: "黑龙江", value: 1784 },
  { name: "上海", value: 1467 },
  { name: "江苏", value: 2568 },
  { name: "浙江", value: 1345 },
  { name: "安徽", value: 1267 },
  { name: "福建", value: 1242 },
  { name: "江西", value: 1235 },
  { name: "山东", value: 1123 },
  { name: "河南", value: 823 },
  { name: "湖北", value: 2623 },
  { name: "湖南", value: 963 },
  { name: "重庆", value: 1356 },
  { name: "四川", value: 2356 },
  { name: "贵州", value: 2678 },
  { name: "云南", value: 2678 },
  { name: "西藏", value: 956 },
  { name: "陕西", value: 2356 },
  { name: "甘肃", value: 2567 },
  { name: "青海", value: 1234 },
  { name: "宁夏", value: 2234 },
  { name: "新疆", value: 1280 },
  { name: "广东", value: 1233 },
  { name: "广西", value: 2234 },
];
function convertData(data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
}
const mapData = convertData(mapArray);
export default {
  name: "Charts",
  components: { Numbers },
  mounted() {
    this.chartInit();
  },
  data() {
    return {
      myChart: null,
    };
  },
  methods: {
    /**
     * 随机数据
     */
    randomData() {
      let flag = true;
      let arrayShow = [];
      let randomArray = [];
      while (flag) {
        const random = Math.floor(Math.random() * 30);
        if (!randomArray.includes(random)) {
          randomArray.push(random);
          arrayShow.push(mapData[random]);
        }
        if (arrayShow.length === 20) {
          flag = false;
        }
      }
      return arrayShow;
    },
    chartInit() {
      this.myChart = echarts.init(document.getElementById("myChartMap"));
      echarts.registerMap("china", China);
      let option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderColor: "rgba(0, 0, 0, 0.8)",
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "china",
          aspectScale: 0.75,
          tooltip: {
            show: false,
          },
          roam: false,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            areaColor: "#6F468A",
            shadowColor: "rgba(148, 39, 110, 0.5)",
            shadowOffsetX: 2,
            shadowOffsetY: 10,
            borderColor: "rgba(239, 152, 226, 0.6)",
            borderWidth: 3,
          },
        },
        series: [
          {
            tooltip: {
              show: false,
            },
            type: "map",
            map: "china",
            aspectScale: 0.75,
            roam: false,
            showLegendSymbol: false,
            label: {
              color: "rgba(1, 255, 255, 0.8)",
            },
            itemStyle: {
              areaColor: "#084FD0",
              shadowColor: "rgba(255, 255, 255, 0.5)",
              shadowBlur: 5,
              borderColor: "rgba(255, 255, 255, 0.5)",
              borderWidth: 1,
            },
            emphasis: {
              itemStyle: {
                areaColor: "#6F468A",
                shadowColor: "rgba(0, 0, 0, 0.8)",
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowOffsetY: 5,
              },
              label: {
                show: false,
                color: "rgba(1, 255, 255, 0.8)",
              },
            },
            animation: false,
          },
          {
            name: "",
            tooltip: {
              show: true,
              formatter(value) {
                return value.name + "：" + value.value[2];
              },
            },
            type: "effectScatter",
            coordinateSystem: "geo",
            data: this.randomData(),
            symbolSize: function (val) {
              return val[2] / 100;
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                color: "#fff",
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(255, 252, 37, 1)",
              },
            },
            zlevel: 1,
            animation: true,
            animationDuration: 1000,
            animationEasing: "cubicOut",
          },
        ],
      };
      this.myChart.setOption(option);
      const timer = setInterval(() => {
        option.series[1].data = this.randomData();
        this.myChart.setOption(option);
      }, 10000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.map_wrap {
  position: relative;
  width: 100%;
  min-height: 616px;
  height: 64.3%;
}
.chart_class {
  width: 100%;
  height: 100%;
}
.number_position {
  position: absolute;
  left: 110px;
  top: 0;
}
</style>
