<template>
  <div :style="{ width, height, padding }" class="card_wrap">
    <div class="card_title" :style="{ paddingTop: paddingTop }">
      <div class="card_line"></div>
      <img class="card_img" src="../../assets/images/icon_r.png" alt="title" />
      <span>{{ title }}</span>
    </div>
    <div class="card_body">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    paddingTop: {
      type: String,
      default: "24px",
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "298px",
    },
    padding: {
      type: String,
      default: "0 24px",
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes twinkling {
  0% {
    filter: drop-shadow(0 0 0 #fff);
  }
  25% {
    filter: drop-shadow(0 0 5px #fff);
  }
  50% {
    filter: drop-shadow(0 0 10px #fff);
  }
  75% {
    filter: drop-shadow(0 0 5px #fff);
  }
  100% {
    filter: drop-shadow(0 0 0 #fff);
  }
}
.card_img {
  animation: twinkling 2s linear infinite;
}
.card_wrap {
  box-sizing: border-box;
  background-color: rgba(15, 38, 85, 0.3);

  .card_title {
    padding-top: 24px;
    display: flex;
    align-items: center;

    .card_line {
      width: 4px;
      height: 22px;
      background-color: #fa2f7c;
    }

    > img {
      margin-left: 20px;
      margin-right: 14px;
      width: 16px;
      height: 16px;
    }

    > span {
      font-family: Source Han Sans CN;
      font-size: 18px;
      color: #fff;
      text-shadow: 0 0 5px #ff1562;
    }
  }

  .card_body {
    width: 100%;
    height: calc(100% - 50px);
  }
}
</style>
