<template>
  <Card title="消防占道" width="50%" height="100%" padding="0 24px">
    <img class="xf_image" src="../../assets/images/xfzd.png" alt="消防占道" />
    <div class="xf_content">
      <p>监控地点：1号监控点</p>
      <p>占位车牌：渝Bxxxxx（蓝牌）</p>
      <p>发生时间：2021-01-02 12:30</p>
    </div>
    <div class="xf_a" @click="imageModel = true">查看占道图</div>
    <el-image-viewer
      v-if="imageModel"
      :on-close="imageClose"
      :url-list="imageList"
    ></el-image-viewer>
  </Card>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Card from "@/components/Card";
export default {
  name: "Firefighting",
  components: { Card, ElImageViewer },
  data() {
    return {
      imageModel: false,
      imageList: [require("../../assets/images/xfzd.png")],
    };
  },
  methods: {
    imageClose() {
      this.imageModel = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.xf_image {
  margin-top: 16px;
  width: 100%;
  box-shadow: 0 0 10px #603172;
}

.xf_content {
  margin-top: 22px;
  > p {
    margin-top: 0;
    font-size: 12px;
    color: #fff;
    margin-bottom: 12px;
  }
}

.xf_a {
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  color: #fa2f7c;
  border-bottom: 1px solid #fa2f7c;
}
</style>
