<template>
  <Card title="高空抛物" height="39.6%">
    <div class="container_class">
      <div class="parabolic_body">
        <div class="parabolic_tips">安全监控30天</div>
        <div class="parabolic_wrap" @mouseenter="enter" @mouseleave="leave">
          <div :class="{ animation: animation }" class="parabolic_main">
            <template v-for="item in list">
              <div :key="item.id" class="parabolic_item">
                <div class="parabolic_image">
                  <img :src="item.image" :alt="item.suspicion" />
                </div>
                <div class="parabolic_content">
                  <p>疑似物体：{{ item.suspicion }}</p>
                  <p>监控地点：{{ item.name }}</p>
                  <p>发生时间：{{ item.time }}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card";
export default {
  name: "Parabolic",
  components: { Card },
  data() {
    return {
      timer: null,
      animation: false,
      list: [
        {
          id: "xn",
          image: require("@/assets/images/jk_2.jpg"),
          suspicion: "飞鸟",
          name: "1号监控点",
          time: "2021-06-02 11:30",
        },
        {
          id: "hp",
          image: require("@/assets/images/jk_t.jpg"),
          suspicion: "花盆",
          name: "10号监控点",
          time: "2021-07-12 18:12",
        },
        {
          id: "sld",
          image: require("@/assets/images/jk_2.jpg"),
          suspicion: "塑料带",
          name: "4号监控点",
          time: "2021-07-14 18:50",
        },
        {
          id: "bl",
          image: require("@/assets/images/jk_t.jpg"),
          suspicion: "玻璃",
          name: "3号监控点",
          time: "2021-07-18 02:11",
        },
        {
          id: "ad",
          image: require("@/assets/images/jk_t.jpg"),
          suspicion: "飞鸟",
          name: "9号监控点",
          time: "2021-07-18 06:11",
        },
        {
          id: "d1a",
          image: require("@/assets/images/jk_t.jpg"),
          suspicion: "塑料带",
          name: "4号监控点",
          time: "2021-07-19 09:11",
        },
      ],
    };
  },
  mounted() {
    this.timer = setInterval(this.rollInit, 2000);
  },
  methods: {
    rollInit() {
      this.animation = true;
      setTimeout(() => {
        this.animation = false;
        const shift = this.list.shift();
        this.list.push(shift);
      }, 900);
    },
    enter() {
      clearInterval(this.timer);
    },
    leave() {
      this.timer = setInterval(this.rollInit, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-99px);
  }
}

.animation {
  animation: animation 1s ease-in-out;
}

.container_class {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.parabolic_body {
  width: 100%;
  height: 90%;
  position: relative;
  .parabolic_tips {
    position: absolute;
    right: -10px;
    top: -42px;
    color: #36e46b;
    font-size: 16px;
    line-height: 1;
  }
}
.parabolic_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .parabolic_main {
    .parabolic_item {
      display: flex;
      box-sizing: border-box;
      padding-bottom: 8px;
      border-bottom: 1px solid #603172;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .parabolic_image {
        margin-right: 20px;
        width: 106px;
        height: 80px;
        box-shadow: 0 0 10px #603172;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .parabolic_content {
        > p {
          margin-top: 6px;
          font-size: 14px;
          color: #fff;
          line-height: 1;

          &:not(:first-child) {
            margin: 14px 0 0;
          }
        }
      }
    }
  }
}
</style>
