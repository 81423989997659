<template>
  <card title="房屋预警" height="100%">
    <div class="container_class">
      <div class="list_wrap" @mouseenter="enter" @mouseleave="leave">
        <div :style="pos" class="list_main">
          <div
            v-for="item in list"
            :key="item.id"
            :class="{
              list_item_ordinary: item.type === 1,
              list_item_urgency: item.type === 2,
              list_item_importance: item.type === 3,
              bg_active: item.id % 2 !== 0,
            }"
            class="list_item"
          >
            <span class="item_order">{{ item.id }}</span>
            <div v-if="item.type === 2" class="item_label">紧急</div>
            <div v-else-if="item.type === 3" class="item_label">重要</div>
            <div v-else class="item_label">一般</div>
            <div class="item_name">{{ item.name }}</div>
            <div class="item_type">{{ item.typeName }}</div>
            <div class="item_time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import Card from "@/components/Card";
export default {
  name: "HouseWarn",
  components: { Card },
  data() {
    return {
      scrollStop: false,
      reqFrame: null,
      translateY: 0,
      list: [
        {
          id: "1",
          name: "1011",
          type: 1,
          typeName: "疑似群租房",
          time: "2021-07-14 12:34",
        },
        {
          id: "2",
          name: "1012",
          type: 2,
          typeName: "疑似群租房",
          time: "2021-07-14 14:10",
        },
        {
          id: "3",
          name: "2011",
          type: 3,
          typeName: "疑似民宿",
          time: "2021-07-17 09:13",
        },
        {
          id: "4",
          name: "1211",
          type: 2,
          typeName: "疑似群租房",
          time: "2021-07-17 18:52",
        },
        {
          id: "5",
          name: "3011",
          type: 2,
          typeName: "疑似民宿",
          time: "2021-07-17 18:58",
        },
        {
          id: "6",
          name: "3211",
          type: 3,
          typeName: "疑似民宿",
          time: "2021-07-17 19:11",
        },
        {
          id: "7",
          name: "3013",
          type: 1,
          typeName: "疑似民宿",
          time: "2021-07-18 10:34",
        },
        {
          id: "8",
          name: "6011",
          type: 3,
          typeName: "疑似群租房",
          time: "2021-07-18 22:49",
        },
        {
          id: "9",
          name: "5051",
          type: 2,
          typeName: "疑似民宿",
          time: "2021-07-18 22:49",
        },
        {
          id: "10",
          name: "4111",
          type: 1,
          typeName: "疑似群租房",
          time: "2021-07-18 22:49",
        },
        {
          id: "11",
          name: "6211",
          type: 3,
          typeName: "疑似民宿",
          time: "2021-07-18 22:49",
        },
        {
          id: "12",
          name: "6031",
          type: 1,
          typeName: "疑似群租房",
          time: "2021-07-18 22:49",
        },
      ],
    };
  },
  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    this.cancelAnimationFrame();
  },
  computed: {
    pos() {
      return { transform: `translate(0, -${this.translateY}px)` };
    },
  },
  methods: {
    initScroll() {
      if (this.scrollStop) {
        return;
      }
      if (this.translateY < 40) {
        this.translateY += 0.5;
      } else {
        this.translateY = 0;
        const shift = this.list.shift();
        this.list.push(shift);
      }
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame;
      this.reqFrame = requestAnimationFrame(this.initScroll);
    },
    cancelAnimationFrame() {
      const cancelAnimationFrame =
        window.cancelAnimationFrame ||
        window.webkitCancelAnimationFrame ||
        window.mozCancelAnimationFrame;
      cancelAnimationFrame(this.reqFrame || "");
    },
    enter() {
      this.scrollStop = true;
    },
    leave() {
      this.scrollStop = false;
      this.initScroll();
    },
  },
};
</script>
<style lang="scss" scoped>
.container_class {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.list_wrap {
  width: 100%;
  height: 90%;
  overflow: hidden;

  .list_main {
    .list_item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;

      .item_order {
        width: 31px;
        text-align: center;
        font-size: 16px;
        color: #43bafe;
        font-weight: bold;
      }

      .item_label {
        padding: 3px 6px;
        color: #071e43;
        border-radius: 2px;
        background-color: #43bafe;
        font-size: 14px;
      }

      .item_name,
      .item_type,
      .item_time {
        line-height: 1;
        color: #fff;
        font-size: 16px;
      }
      .item_name {
        text-align: center;
        margin-left: 13px;
        flex: 1 0 45px;
      }
      .item_type {
        margin-left: 15px;
        flex: 1 0 85px;
      }
      .item_time {
        white-space: nowrap;
        flex: 1;
        padding-right: 14px;
      }
    }

    .bg_active {
      background-color: rgba(36, 75, 169, 0.3);
    }

    .list_item_urgency {
      .item_label {
        background-color: #f8a036;
      }
      .item_order {
        color: #f8a036;
      }
    }

    .list_item_importance {
      .item_label {
        background-color: #ff344c;
      }
      .item_order {
        color: #ff344c;
      }
    }
  }
}
</style>
