<template>
  <Card title="人员布控" height="23%">
    <div class="container_class">
      <div class="control_wrap">
        <div class="control_image">
          <img src="../../assets/images/rybk.png" />
        </div>
        <div class="control_content">
          <div class="control_content_name">
            <span>张珊珊</span>
            <img src="../../assets/images/nv.png" />
          </div>
          <p>134***7845</p>
          <p>500241*****0147</p>
          <p>一碗水后街12号1栋2单元</p>
          <div class="control_content_label">
            <div class="label_1">嫌疑人</div>
            <div class="label_2">重点人员</div>
          </div>
        </div>
        <div class="control_a">点击查看</div>
      </div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card";
export default {
  name: "Control",
  components: { Card },
};
</script>
<style lang="scss" scoped>
.container_class {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.control_wrap {
  position: relative;
  display: flex;
  width: inherit;
  height: 90%;

  .control_a {
    cursor: pointer;
    position: absolute;
    bottom: -6px;
    right: -10px;
    font-size: 14px;
    color: #fa2f7c;
    height: 20px;
    border-bottom: 1px solid #fa2f7c;
  }

  .control_image {
    width: 50%;
    height: 100%;
    box-shadow: 0 0 10px #603172;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .control_content {
    margin-left: 22px;
    .control_content_name {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      > span {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }

      > img {
        margin-left: 12px;
        width: 29px;
        height: 29px;
      }
    }

    > p {
      margin-top: 0;
      font-size: 14px;
      color: #fff;
      line-height: 1;
      margin-bottom: 12px;
    }

    .control_content_label {
      display: flex;

      .label_1 {
        font-size: 14px;
        padding: 3px 6px;
        background-color: rgba(255, 52, 76, 0.28);
        color: #ff344c;
      }

      .label_2 {
        margin-left: 8px;
        font-size: 14px;
        padding: 3px 6px;
        background-color: rgba(248, 160, 54, 0.28);
        color: #f8a036;
      }
    }
  }
}
</style>
