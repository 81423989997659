<template>
  <Card title="数据统计" height="50%">
    <div id="myChart" class="tj_wrap"></div>
    <div class="tj_line"></div>
    <div class="device_wrap">
      <div class="device_title">设备在线率</div>
      <div id="devChart" class="device_chart"></div>
      <div class="device_count">设备总数 <span>15327</span></div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card";
import * as echarts from "echarts";
export default {
  name: "Statistics",
  components: { Card },
  data() {
    return {
      myChart: null,
      devChart: null,
    };
  },
  mounted() {
    this.chartInit();
  },
  methods: {
    chartInit() {
      this.myChart = echarts.init(document.getElementById("myChart"));
      this.devChart = echarts.init(document.getElementById("devChart"));
      setInterval(() => {
        const random = Math.floor(Math.random() * (100 - 85)) + 85;
        this.devChart.setOption({
          yAxis: [
            {
              id: "setData3",
              data: [random],
            },
          ],
          series: [
            {
              id: "setData1",
              data: [random],
            },
            {
              id: "setData2",
              data: [random],
            },
          ],
        });
      }, 5000);
      this.devChart.setOption({
        xAxis: {
          max: 100,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          left: 0,
          top: 0, // 设置条形图的边距
          right: 0,
          bottom: 0,
        },
        yAxis: [
          {
            id: "setData3",
            type: "category",
            inverse: false,
            stack: "1",
            data: [87],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        series: [
          {
            //间距
            type: "bar",
            barWidth: 14,
            stack: "1",
            legendHoverLink: false,
            itemStyle: {
              normal: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
            z: 3,
            data: [1],
          },
          {
            id: "setData2",
            // 内
            type: "bar",
            stack: "1",
            barWidth: 14,
            silent: true,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "#0046AA",
                  },
                  {
                    offset: 1,
                    color: "#F524B1",
                  },
                ]),
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            data: [87],
            z: 1,
            // animationEasing: "quadraticOut",
            animation: false,
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#101F41",
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 20,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [3, 14],
            symbolPosition: "start",
            symbolOffset: [38, -2],
            symbolBoundingData: 100,
            data: [100],
            z: 2,
            animation: false,
          },
          {
            id: "setData1",
            // label
            type: "pictorialBar",
            symbolBoundingData: 100,
            itemStyle: {
              normal: {
                color: "none",
              },
            },
            label: {
              normal: {
                formatter: (params) => {
                  let text;
                  text = "{f| " + (params.data * 100) / 100 + "%}";
                  return text;
                },
                rich: {
                  f: {
                    color: "#FFFFFF",
                    fontSize: 12,
                    fontWeight: 400,
                    shadowColor: "#ffdce9",
                    shadowBlur: 10,
                  },
                },
                position: "right",
                distance: -40, // 向右偏移位置
                show: true,
              },
            },
            data: [87],
            z: 4,
          },
          {
            name: "外框",
            type: "bar",
            barGap: "-120%", // 设置外框粗细
            data: [100],
            barWidth: 20,
            itemStyle: {
              normal: {
                color: "#101F41", // 填充色
                borderType: "solid",
                barBorderColor: "#91A9FF", // 边框色
                barBorderWidth: 1, // 边框宽度
              },
            },
            z: 0,
            animation: false,
          },
        ],
      });
      this.updateChart();
      const timer = setInterval(() => {
        this.updateChart();
      }, 5000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
    updateChart() {
      let option = {
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderColor: "rgba(0, 0, 0, 0.8)",
          textStyle: {
            color: "#fff",
          },
        },
        title: [
          {
            text: "人员流动/常住占比",
            left: "13%",
            top: "45%",
            textAlign: "center",
            textBaseline: "middle",
            textStyle: {
              fontSize: 14,
              color: "#F9DCE5",
              fontWeight: "normal",
            },
          },
          {
            text: "房屋类型占比",
            right: "11%",
            top: "45%",
            textAlign: "center",
            textBaseline: "middle",
            textStyle: {
              fontSize: 14,
              color: "#F9DCE5",
              fontWeight: "normal",
            },
          },
          {
            text: "年龄阶段占比",
            left: "13%",
            bottom: "4%",
            textAlign: "center",
            textBaseline: "middle",
            textStyle: {
              fontSize: 14,
              color: "#F9DCE5",
              fontWeight: "normal",
            },
          },
          {
            text: "设备分类占比",
            right: "11%",
            bottom: "4%",
            textAlign: "center",
            textBaseline: "middle",
            textStyle: {
              fontSize: 14,
              color: "#F9DCE5",
              fontWeight: "normal",
            },
          },
        ],
        legend: [
          {
            orient: "vertical",
            left: "30%",
            top: "16%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: "#F3D8E8",
              fontSize: 12,
            },
            data: ["流动人口", "常住人口"],
          },
          {
            orient: "vertical",
            right: "0",
            top: "5%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: "#F3D8E8",
              fontSize: 12,
            },
            data: ["安置房", "公租房", "廉租房", "老旧小区", "单体楼"],
          },
          {
            orient: "vertical",
            left: "30%",
            bottom: "15%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: "#F3D8E8",
              fontSize: 12,
            },
            data: ["0-18", "19-30", "31-45", "46-60", "61-100"],
          },
          {
            orient: "vertical",
            right: "0",
            bottom: "15%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              color: "#F3D8E8",
              fontSize: 12,
            },
            data: ["门禁", "摄像头", "传感器", "车闸", "高空瞭望"],
          },
        ],
        series: [
          {
            name: "人员流动/常住占比",
            type: "pie",
            radius: ["20%", "30%"],
            center: ["15%", "23%"],
            label: {
              show: false,
            },
            data: [
              {
                value: this.getRandom(),
                name: "流动人口",
                itemStyle: {
                  color: "rgba(245, 58, 134, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#F53A86",
                  shadowColor: "#F53A86",
                },
              },
              {
                value: this.getRandom(),
                name: "常住人口",
                itemStyle: {
                  color: "rgba(8, 117, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#0B77FC",
                  shadowColor: "#0B77FC",
                },
              },
            ],
          },
          {
            name: "房屋类型占比",
            type: "pie",
            radius: ["20%", "30%"],
            center: ["66%", "23%"],
            label: {
              show: false,
            },
            data: [
              {
                value: this.getRandom(),
                name: "安置房",
                itemStyle: {
                  color: "rgba(245, 58, 134, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#F53A86",
                  shadowColor: "#F53A86",
                },
              },
              {
                value: this.getRandom(),
                name: "公租房",
                itemStyle: {
                  color: "rgba(8, 117, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#0B77FC",
                  shadowColor: "#0B77FC",
                },
              },
              {
                value: this.getRandom(),
                name: "廉租房",
                itemStyle: {
                  color: "rgba(255, 177, 11, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFB10B",
                  shadowColor: "#FFB10B",
                },
              },
              {
                value: this.getRandom(),
                name: "老旧小区",
                itemStyle: {
                  color: "rgba(255, 252, 37, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFFC25",
                  shadowColor: "#FFFC25",
                },
              },
              {
                value: this.getRandom(),
                name: "单体楼",
                itemStyle: {
                  color: "rgba(20, 196, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#14C4FC",
                  shadowColor: "#14C4FC",
                },
              },
            ],
          },
          {
            name: "年龄阶段占比",
            type: "pie",
            radius: ["20%", "30%"],
            center: ["15%", "68%"],
            label: {
              show: false,
            },
            data: [
              {
                value: this.getRandom(),
                name: "0-18",
                itemStyle: {
                  color: "rgba(245, 58, 134, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#F53A86",
                  shadowColor: "#F53A86",
                },
              },
              {
                value: this.getRandom(),
                name: "19-30",
                itemStyle: {
                  color: "rgba(8, 117, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#0B77FC",
                  shadowColor: "#0B77FC",
                },
              },
              {
                value: this.getRandom(),
                name: "31-45",
                itemStyle: {
                  color: "rgba(255, 177, 11, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFB10B",
                  shadowColor: "#FFB10B",
                },
              },
              {
                value: this.getRandom(),
                name: "46-60",
                itemStyle: {
                  color: "rgba(255, 252, 37, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFFC25",
                  shadowColor: "#FFFC25",
                },
              },
              {
                value: this.getRandom(),
                name: "61-100",
                itemStyle: {
                  color: "rgba(20, 196, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#14C4FC",
                  shadowColor: "#14C4FC",
                },
              },
            ],
          },
          {
            name: "设备分类占比",
            type: "pie",
            radius: ["20%", "30%"],
            center: ["66%", "68%"],
            label: {
              show: false,
            },
            data: [
              {
                value: this.getRandom(),
                name: "门禁",
                itemStyle: {
                  color: "rgba(245, 58, 134, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#F53A86",
                  shadowColor: "#F53A86",
                },
              },
              {
                value: this.getRandom(),
                name: "摄像头",
                itemStyle: {
                  color: "rgba(8, 117, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#0B77FC",
                  shadowColor: "#0B77FC",
                },
              },
              {
                value: this.getRandom(),
                name: "传感器",
                itemStyle: {
                  color: "rgba(255, 177, 11, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFB10B",
                  shadowColor: "#FFB10B",
                },
              },
              {
                value: this.getRandom(),
                name: "车闸",
                itemStyle: {
                  color: "rgba(255, 252, 37, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#FFFC25",
                  shadowColor: "#FFFC25",
                },
              },
              {
                value: this.getRandom(),
                name: "高空瞭望",
                itemStyle: {
                  color: "rgba(20, 196, 252, 0.2)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  borderColor: "#14C4FC",
                  shadowColor: "#14C4FC",
                },
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option);
    },
    getRandom() {
      return Math.floor(Math.random() * (2000 - 400 + 1) + 400);
    },
  },
};
</script>
<style lang="scss" scoped>
.tj_wrap {
  width: 100%;
  height: 75%;
}
.tj_line {
  width: 90%;
  height: 1px;
  background-color: #fa2f7c;
  margin: auto;
}

.device_wrap {
  width: 90%;
  margin: 20px auto 0;

  .device_title {
    font-size: 14px;
    color: #fff;
    text-shadow: 0 0 5px #fa2f7c;
  }

  .device_chart {
    margin-top: 10px;
    width: 90%;
    height: 26px;
  }
  .device_count {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-shadow: 0 0 5px #fa2f7c;
    > span {
      margin-left: 8px;
      color: #ff75a9;
      text-shadow: 0 0 5px #ff75a9;
    }
  }
}
</style>
