<template>
  <div
    class="home_wrap"
    :style="{ width: bodyWidth + 'px', height: bodyHeight + 'px' }"
  >
    <div style="width: 100%; height: 100px;"></div>
    <iframe style="border: none;position: absolute; left: 0; top: 0;" width="100%" height="100%" src="https://ay.aierbanjia.com" />
    <div class="nav_wrap animation_title">
      <div class="nav_main">
        <div class="nav_bar">
          <template v-for="(item, index) in navList1">
            <div
              :key="item.name"
              class="nav_bar_item"
              @mouseover="mouseover($event, 'menu_1')"
              @mouseout="mouseout($event, 'menu_1')"
            >
              <div class="nav_bar_text">
                <div :data-index="index" class="nav_bar_data"></div>
                <span>{{ item.name }}</span>
                <img class="nav_bar_btn" src="../assets/images/nav.png" />
                <img
                  class="nav_bar_btn_hover"
                  src="../assets/images/nav_hover.png"
                />
              </div>
              <transition name="menuShow">
                <div
                  v-show="menu_1[index]"
                  class="nav_bar_menu"
                  @mouseenter="mouseenter('menu_1', index)"
                  @mouseleave="mouseleave('menu_1', index)"
                >
                  <div
                    v-for="child in item.children"
                    :key="child.id"
                    class="nav_menu_item"
                  >
                    {{ child.name }}
                  </div>
                </div>
              </transition>
            </div>
          </template>
        </div>
        <div class="nav_title">
          <img src="../assets/images/logo.png" alt="logo" />
          <span>艾尔伴家社区微脑</span>
        </div>
        <div class="nav_bar">
          <template v-for="(item, index) in navList2">
            <div
              :key="item.name"
              class="nav_bar_item"
              @mouseover="mouseover($event, 'menu_2')"
              @mouseout="mouseout($event, 'menu_2')"
            >
              <div class="nav_bar_text">
                <div :data-index="index" class="nav_bar_data"></div>
                <span>{{ item.name }}</span>
                <img class="nav_bar_btn" src="../assets/images/nav.png" />
                <img
                  class="nav_bar_btn_hover"
                  src="../assets/images/nav_hover.png"
                />
              </div>
              <transition name="menuShow">
                <div
                  v-show="menu_2[index]"
                  class="nav_bar_menu"
                  @mouseenter="mouseenter('menu_2', index)"
                  @mouseleave="mouseleave('menu_2', index)"
                >
                  <div
                    v-for="child in item.children"
                    :key="child.id"
                    class="nav_menu_item"
                  >
                    {{ child.name }}
                  </div>
                </div>
              </transition>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="home_content">
      <div class="home_left">
        <Statistics class="animation_left_1" style="margin-bottom: 20px" />
        <Reported class="animation_left_2" style="margin-bottom: 20px" />
        <PersonWarn class="animation_left_3" />
      </div>
      <div class="home_center">
<!--        <Map class="animation_center_1" />-->
        <div style="width: 100%; height: 64.3%" />
        <div class="home_center_flex">
          <HouseWarn class="animation_center_2" style="margin-right: 20px" />
          <DeviceWarn class="animation_center_3" />
        </div>
      </div>
      <div class="home_right">
        <Parabolic class="animation_right_1" style="margin-bottom: 20px" />
        <Control class="animation_right_2" />
        <div class="home_right_flex animation_right_3">
          <Firefighting style="margin-right: 24px" />
          <Response />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Map from "@/components/charts/Map";
import PersonWarn from "./module/PersonWarn";
import HouseWarn from "./module/HouseWarn";
import DeviceWarn from "./module/DeviceWarn";
import Parabolic from "./module/Parabolic";
import Control from "./module/Control";
import Firefighting from "./module/Firefighting";
import Response from "./module/Response";
import Reported from "./module/Reported";
import Statistics from "./module/Statistics";
export default {
  name: "Home",
  components: {
    Statistics,
    Reported,
    Response,
    Firefighting,
    Control,
    Parabolic,
    PersonWarn,
    HouseWarn,
    DeviceWarn,
    Map,
  },
  computed: {
    ...mapGetters(["bodyWidth", "bodyHeight"]),
  },
  data() {
    return {
      timer: null,
      menu_1: [false, false, false],
      menu_2: [false, false, false],
      navList1: [
        {
          name: "智能社区",
          menu: false,
          children: [
            { id: "3ee2", name: "数字孪生社区" },
            { id: "3110qq", name: "基础数据采集" },
            { id: "4qwerq", name: "事件智能分拨" },
          ],
        },
        {
          name: "智治社区",
          menu: false,
          children: [
            { id: "2asfda", name: "社区信息发布" },
            { id: "asfqwe3", name: "矛盾纠纷调解" },
            { id: "4gasda", name: "共治队伍管理" },
          ],
        },
        {
          name: "智居社区",
          menu: false,
          children: [
            { id: "asfasd2", name: "线上服务超市" },
            { id: "asdfgg3", name: "社区综合受理" },
            { id: "4asffga", name: "学籍管理" },
          ],
        },
      ],
      navList2: [
        {
          name: "智防社区",
          menu: false,
          children: [
            { id: "2asda", name: "消防管理系统" },
            { id: "asasd3", name: "视频监控系统" },
            { id: "vbbvas4", name: "门禁管理系统" },
          ],
        },
        {
          name: "智养社区",
          menu: false,
          children: [
            { id: "vbas2", name: "老人信息管理" },
            { id: "3afasd", name: "关怀服务" },
            { id: "4afasdas", name: "远程看护" },
          ],
        },
        {
          name: "智产社区",
          menu: false,
          children: [
            { id: "110qsfasdf2", name: "企业大数据" },
            { id: "3asfasdf1", name: "智慧走访" },
            { id: "12edfa4", name: "企业服务" },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    mouseover(event, type) {
      const { index } = event.target.dataset;
      this.$set(this[type], index, true);
    },
    mouseout(event, type) {
      const { index } = event.target.dataset;
      this.timer = setTimeout(() => {
        this.$set(this[type], index, false);
      }, 100);
    },
    mouseenter() {
      clearTimeout(this.timer);
    },
    mouseleave(type, index) {
      this.$set(this[type], index, false);
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes title_animate {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes left_animate {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes right_animate {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes center_animate {
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes center_animate_o {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation_center_1 {
  opacity: 0;
  animation: center_animate_o 2s cubic-bezier(0.6, 0.04, 0, 0.81);
  animation-fill-mode: forwards;
}

.animation_center_2 {
  transform: translateY(100%);
  opacity: 0.5;
  animation: center_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.1s;
  animation-fill-mode: forwards;
}

.animation_center_3 {
  transform: translateY(100%);
  opacity: 0.5;
  animation: center_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.2s;
  animation-fill-mode: forwards;
}

.animation_title {
  //transform: translateY(-100%);
  animation: title_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81);
  //animation-fill-mode: forwards;
}

.animation_left_1 {
  transform: translateX(-100%);
  animation: left_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81);
  animation-fill-mode: forwards;
}
.animation_left_2 {
  transform: translateX(-100%);
  animation: left_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.1s;
  animation-fill-mode: forwards;
}
.animation_left_3 {
  transform: translateX(-100%);
  animation: left_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.2s;
  animation-fill-mode: forwards;
}

.animation_right_1 {
  transform: translateX(100%);
  animation: right_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81);
  animation-fill-mode: forwards;
}

.animation_right_2 {
  transform: translateX(100%);
  animation: right_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.1s;
  animation-fill-mode: forwards;
}

.animation_right_3 {
  transform: translateX(100%);
  animation: right_animate 1s cubic-bezier(0.6, 0.04, 0, 0.81) 0.2s;
  animation-fill-mode: forwards;
}

.menuShow-enter-active,
.menuShow-leave-active {
  transition: all 0.5s;
}
.menuShow-enter, .menuShow-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}
.home_wrap {
  position: relative;
  min-width: 1920px;
  min-height: 1080px;
  background: url("~@/assets/images/bg_title.gif");
  background-size: 100%;
  overflow: hidden;

  .bg_video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .nav_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    background: url("~@/assets/images/nav_bg.png") no-repeat;
    background-size: 100% 100%;

    .nav_main {
      padding: 0 24px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav_title {
        display: flex;
        align-items: center;
        > img {
          width: 34px;
          height: 34px;
          filter: drop-shadow(0 0 10px #9e3d74);
        }
        > span {
          white-space: nowrap;
          line-height: 1;
          font-family: "pangmenzhengdao";
          margin-left: 14px;
          color: #fff7fc;
          font-size: 34px;
          text-shadow: 0 0 10px #9e3d74;
        }
      }

      .nav_bar {
        display: flex;
        .nav_bar_item {
          position: relative;
          width: 169px;
          height: 46px;
          cursor: pointer;
          text-align: center;
          line-height: 40px;

          &:not(:first-child) {
            margin-left: 16px;
          }

          .nav_bar_text {
            width: 100%;
            height: 100%;
            position: relative;
            .nav_bar_data {
              position: absolute;
              z-index: 9;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
            > span {
              font-size: 18px;
              color: #ffebf4;
              text-shadow: 0 0 10px #9e3d74;
            }

            .nav_bar_btn,
            .nav_bar_btn_hover {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              transition: all 0.2s;
            }
            .nav_bar_btn {
              opacity: 1;
            }

            .nav_bar_btn_hover {
              opacity: 0;
            }
          }

          .nav_bar_menu {
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            clip-path: polygon(85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
            box-shadow: 0 0 15px #9e3d74 inset;
            width: 100%;
            z-index: 99;
            background-color: #281141;

            .nav_menu_item {
              margin: 0 32px;
              height: 46px;
              line-height: 46px;
              box-sizing: border-box;
              color: #fff;
              font-size: 14px;

              &:not(:last-child) {
                border-bottom: 1px solid #c32e82;
              }

              &:hover {
                font-weight: bold;
                text-shadow: 0 0 10px #9e3d74;
              }
            }
          }

          &:hover {
            .nav_bar_btn {
              opacity: 0;
            }
            .nav_bar_btn_hover {
              opacity: 1;
              animation: 2s loading ease-in-out infinite;
            }
          }
          @keyframes loading {
            0% {
              filter: drop-shadow(0 0 0 #9e3d74);
            }
            50% {
              filter: drop-shadow(0 0 5px #9e3d74);
            }
            100% {
              filter: drop-shadow(0 0 0 #9e3d74);
            }
          }
        }
      }
    }
  }

  .home_content {
    width: calc(100% - 48px);
    display: flex;
    margin-top: 18px;
    padding: 0 24px;
    height: calc(100% - 118px);

    .home_left {
      width: 24%;
    }

    .home_center {
      width: 49%;
      margin: 0 24px;

      .home_center_flex {
        height: 32%;
        margin-top: 24px;
        display: flex;
      }
    }

    .home_right {
      width: 24%;
      .home_right_flex {
        width: 100%;
        height: 32%;
        margin-top: 24px;
        display: flex;
      }
    }
  }
}
</style>
