<template>
  <Card title="事件上报" height="13%" paddingTop="14px">
    <div class="rep_container">
      <div class="rep_wrap">
        <div class="rep_main">
          <div class="rep_item">
            <span class="rep_number">
              <countTo
                :startVal="old_number_1"
                :endVal="number_1"
                :duration="3000"
              ></countTo>
            </span>
            <span class="rep_unit">件</span>
          </div>
          <div class="rep_line"></div>
          <div class="rep_item">
            <span class="rep_number">
              <countTo
                :startVal="old_number_2"
                :endVal="number_2"
                :duration="3000"
              ></countTo>
            </span>
            <span class="rep_unit">件</span>
          </div>
          <div class="rep_line"></div>
          <div class="rep_item">
            <span class="rep_number">
              <countTo :startVal="0" :endVal="98" :duration="3000"></countTo>
            </span>
            <span class="rep_unit">%</span>
          </div>
        </div>
        <div class="rep_tip">
          <div class="rep_tip_item">上报事件数</div>
          <div class="rep_tip_item">已解决数</div>
          <div class="rep_tip_item">满意率</div>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import countTo from "vue-count-to";
import Card from "@/components/Card";
export default {
  name: "Reported",
  components: { Card, countTo },
  data() {
    return {
      timer: null,
      number_1: 506,
      old_number_1: 0,
      number_2: 413,
      old_number_2: 0,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.initCount();
    }, 5000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
      this.timer = null;
    });
  },
  methods: {
    initCount() {
      const random1 = Math.floor(Math.random() * (100 - 1)) + 1;
      const random2 = Math.floor(Math.random() * (100 - 1)) + 1;
      const number_1 = this.number_1 + (random1 > random2 ? random1 : random2);
      const number_2 = this.number_2 + (random1 > random2 ? random2 : random1);
      this.old_number_1 = this.number_1;
      this.old_number_2 = this.number_2;
      this.number_1 = number_1;
      this.number_2 = number_2;
    },
  },
};
</script>
<style lang="scss" scoped>
.rep_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .rep_wrap {
    width: 100%;
  }
}
.rep_main {
  display: flex;
  justify-content: center;
  align-items: center;
  .rep_item {
    width: calc(100% / 3);
    text-align: center;
    .rep_number {
      display: inline-block;
      font-size: 30px;
      color: #ffdce9;
      text-shadow: 0 0 6px #fb20b7;
      line-height: 1;
    }

    .rep_number_w {
      min-width: 85px;
    }

    .rep_unit {
      margin-left: 10px;
      font-size: 18px;
      color: #ffdce9;
      text-shadow: 0 0 6px #da2e7c;
      line-height: 1;
    }
  }
  .rep_line {
    width: 1px;
    height: 25px;
    background-color: rgba(251, 32, 183, 0.42);
  }
}
.rep_tip {
  margin-top: 6px;
  display: flex;
  .rep_tip_item {
    text-align: center;
    width: calc(100% / 3);
    font-size: 14px;
    color: #f9dce5;
  }
}
</style>
