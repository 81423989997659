<template>
  <div class="number-container">
    <div class="number-text">安全守护次数</div>
    <div class="number-warp">
      <template v-for="(item, index) in numberList">
        <NumberBox
          :key="index + 'a' + index"
          :style="{
            marginLeft: index === 0 || index === 3 || index === 6 ? '0' : '8px',
          }"
          :number="item"
        />
        <span :key="index + 's'" v-if="index === 2 || index === 5" class="dh"
          >,</span
        >
      </template>
    </div>
  </div>
</template>

<script>
import NumberBox from "@/components/NumberBox";

export default {
  name: "Numbers",
  components: {
    NumberBox,
  },
  data() {
    return {
      number1: 1,
      number: 512354,
      numberList: ["0", "0", "0", "5", "1", "2", "3", "5", "4"],
      timer: null,
    };
  },
  mounted() {
    this.show();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    show() {
      this.changeNumber();
      this.timer = setInterval(() => {
        this.changeNumber();
      }, 5000);
    },
    changeNumber() {
      const number =
        this.number + (Math.floor(Math.random() * (999 - 100)) + 100);
      this.number = number;
      this.numberList = Array.from({ length: 9 }, () => "0");
      const arr = [...number.toString()];
      if (arr.length < 9) {
        const length = 9 - arr.length;
        for (let n = 0; n < length; n++) {
          arr.unshift("0");
        }
      }
      this.numberList = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.number-container {
  //background-color: #0d292b;

  .number-text {
    color: #bea3da;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .number-warp {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dh {
    font-family: myFont;
    font-size: 56px;
    color: #ffffff;
  }
}
</style>
