<template>
  <div class="number-box">
    <div class="number-content-top">
      <span>{{ numberData.valueTop }}</span>
    </div>
    <div class="number-content-bottom">
      <span>{{ numberData.valueBottom }}</span>
    </div>
    <div class="front" v-if="show">
      <span>{{ numberData.valueFront }}</span>
    </div>
    <div class="reverse" v-if="show">
      <span>{{ numberData.valueReverse }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberBox",
  props: {
    number: {
      type: String,
      required: true,
    },
  },
  watch: {
    number: {
      handler(newValue, oldValue) {
        this.move(newValue, oldValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.numberData = {
      valueTop: this.number,
      valueBottom: this.number,
      valueFront: this.number,
      valueReverse: this.number,
    };
  },
  data() {
    return {
      numberSelf: 0,
      numberData: {
        valueTop: 0,
        valueBottom: 0,
        valueFront: 0,
        valueReverse: 0,
      },
      show: false,
      timer: null,
      timerTwo: null,
    };
  },
  methods: {
    move(newValue = 0, oldValue = 0) {
      clearTimeout(this.timer);
      clearTimeout(this.timerTwo);
      this.show = false;
      this.numberData = {
        valueTop: oldValue,
        valueBottom: oldValue,
        valueFront: oldValue,
        valueReverse: newValue,
      };
      this.timerTwo = setTimeout(() => {
        this.show = true;
        this.numberData.valueTop = newValue;
      }, 200);
      this.timer = setTimeout(() => {
        this.numberData.valueBottom = this.number;
        this.show = false;
        this.timer = null;
      }, 1800);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fz {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

.number-box {
  position: relative;
  transform-style: preserve-3d;
  perspective: 500;
  width: 38px;
  height: 56px;

  > div {
    position: absolute;
    font-family: myFont-bold;
    /*background-color: #05785B;*/ // old
    background-color: #c32e82;
    display: flex;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    font-size: 56px;
    /*border: 1px solid #03A980;*/ // old
    border: 1px solid #ff84b2;
    box-shadow: 0 0 6px #c32e82;
    width: 38px;
    height: 50%;
    box-sizing: border-box;
  }

  .number-content-bottom {
  }

  .number-content-top,
  .front,
  .reverse {
  }

  .number-content-top {
    /*border-bottom-color: #166450;*/
    border-bottom-color: #0B1F7B;

    > span {
      transform: translateY(-2px);
    }
  }

  .number-content-bottom {
    bottom: 0;
    border-top: none;

    > span {
      transform: translateY(-28px);
    }
  }

  .front {
    top: 0;
    /*border-bottom-color: #166450;*/
    border-bottom-color: #0B1F7B;
    z-index: 3;
    transform-origin: 50% 100%;
    backface-visibility: hidden;
    animation: fz 2s;

    > span {
      transform: translateY(-2px);
    }
  }

  .reverse {
    border-bottom: none;
    top: 0;
    z-index: 2;
    transform-origin: 50% 100%;
    backface-visibility: visible;
    transform: rotateX(180deg);
    animation: fz 2s;

    > span {
      position: relative;
      top: 28px;
      transform: rotateX(180deg);
    }
  }
}
</style>
