<template>
  <Card title="应急响应" width="50%" height="100%" padding="0 18px">
    <div class="res_wrap">
      <div class="res_item res_item_b">
        <span>自然灾害应急预案</span>
        <img class="btn_class" src="../../assets/images/btn.png" alt="" />
        <img class="btn_hover" src="../../assets/images/btn_hover.png" alt="" />
      </div>
      <div class="res_item res_item_b">
        <span>公共卫生应急预案</span>
        <img class="btn_class" src="../../assets/images/btn.png" alt="" />lllllll
        <img class="btn_hover" src="../../assets/images/btn_hover.png" alt="" />
      </div>
      <div class="res_item res_item_b">
        <span>社会安全应急预案</span>
        <img class="btn_class" src="../../assets/images/btn.png" alt="" />
        <img class="btn_hover" src="../../assets/images/btn_hover.png" alt="" />
      </div>
      <div class="res_item">
        <span>事故灾难应急预案</span>
        <img class="btn_class" src="../../assets/images/btn.png" alt="" />
        <img class="btn_hover" src="../../assets/images/btn_hover.png" alt="" />
      </div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card";
export default {
  name: "Response",
  components: { Card },
};
</script>
<style lang="scss" scoped>
.res_wrap {
  margin-top: 28px;

  .res_item_b {
    margin-bottom: 14px;
  }
}
.res_item {
  position: relative;
  width: 100%;
  height: 41px;
  text-align: center;
  line-height: 41px;

  > span {
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-shadow: 0 0 5px #fa2f7c;
  }

  > img {
    width: 100%;
    height: 100%;
  }

  .btn_class,
  .btn_hover {
    position: absolute;
    transition: all 0.3s;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  .btn_class {
    opacity: 1;
  }

  .btn_hover {
    opacity: 0;
  }

  &:hover {
    .btn_class {
      opacity: 0;
    }

    .btn_hover {
      opacity: 1;
    }
  }
}
</style>
